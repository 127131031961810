/* thai */
@font-face {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 200;
  src: local("Mitr ExtraLight"), local("Mitr-ExtraLight"), url(https://fonts.gstatic.com/s/mitr/v1/vLuKsIznq583K1bJ6LH3l_Y6323mHUZFJMgTvxaG2iE.woff2) format("woff2");
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}

/* thai */
@font-face {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 300;
  src: local("Mitr Light"), local("Mitr-Light"), url(https://fonts.gstatic.com/s/mitr/v1/VQD0sdnyldBX3_jSXU12G_Y6323mHUZFJMgTvxaG2iE.woff2) format("woff2");
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}

@media screen and (min-width: 1280px) {
  .screenControl, header .top .content, header .body .content, footer .content, .content {
    width: 1280px;
    margin: auto;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
  }
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Mitr", sans-serif;
  font-size: 14px;
  background-color: #f5f5f5;
}

header {
  width: 100%;
  color: white;
}

@media screen and (min-width: 1023px) {
  header {
    position: fixed;
    top: 0;
    z-index: 3;
    background-image: linear-gradient(0deg, #39a9de 0%, #0091bc 51%, #0071bc 95%);
  }
}

@media screen and (max-width: 1023px) {
  header {
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: white;
  }
}

header .bgBlue {
  background-color: #0071bc;
}

header .top .content {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

header .top .content .divAllCat {
  float: left;
  cursor: pointer;
}

header .top .content .divAllCat a {
  font-size: 12px !important;
}

header .body .icon {
  color: #929292;
  font-size: 20px;
}

header .body .icon:hover {
  font-size: 21px;
}

header .body .hoverPink:hover {
  color: #ff46be !important;
}

header .body .hoverGreen:hover {
  color: #1ddc1d !important;
}

header .body .content .top {
  height: 110px;
}

header .underline {
  height: 2px;
  background-color: #f4ce11;
}

header .text, header a, header a:hover,
header a:focus, header .ecommerceNavbar a, .ecommerceNavbar header a, header .ecommerceNavbar a:hover, .ecommerceNavbar header a:hover,
header .ecommerceNavbar a:focus, .ecommerceNavbar header a:focus {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.productGroup {
  margin-top: 20px;
  margin-bottom: 10px;
}

.productGroup .name {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 15px;
  color: #252525;
  font-family: "Mitr", sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.productGroup .name .underline {
  margin-top: 5px;
  height: 3px;
  width: 0px;
  background-color: #0071bc;
}

.productGroup .name:hover {
  text-decoration: none;
}

.productGroup .name:hover .underline {
  width: 100%;
  transition: 0.5s ease;
}

footer {
  background: #0071bc;
  color: white;
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 11px;
}

.content {
  color: white;
}

input {
  font-weight: "Mitr", sans-serif;
  color: #252525;
  font-size: 14px;
}

.separate {
  height: 1px;
  background-color: #e1e1e1;
}

.vcenter {
  display: flex;
  align-items: center;
  height: inherit;
}

.hoverEvent, header .top .content .divAllCat .a:hover, header .body .icon:hover, header a:hover,
header a:focus, .viewProduct .thump:hover, .viewProduct .arrowRecomendedItem:hover {
  color: #f4ce11;
  transition: 0.5s ease;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .flag {
    font-size: 12px;
    display: flex;
    position: absolute;
    float: right;
  }
}

@media screen and (min-width: 1280px) {
  .flag {
    display: flex;
    margin-top: -170px;
    position: absolute;
    font-size: 30px;
  }
}

.flag .productGroupBannerHeaderRibbin {
  height: 71px;
  width: 10px;
  float: left;
  border: 1px solid;
  margin-left: -15px;
}

@media screen and (max-width: 1280px) {
  .flag .productGroupBannerHeaderRibbin {
    display: none;
  }
}

.flag .productGroupBannerHeader {
  float: left;
  padding-left: 15px;
}

@media screen and (max-width: 1280px) {
  .flag .productGroupBannerHeader {
    height: 40px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .flag .productGroupBannerHeader {
    height: 71px;
  }
}

.flag .bottomleft {
  width: 0;
  height: 0;
  border-bottom: 71px solid;
  border-right: 70px solid transparent;
  float: left;
}

.flag .topleft {
  width: 0;
  height: 0;
  border-top: 70px solid;
  border-right: 70px solid transparent;
  margin-left: -70px;
  float: left;
}

.item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  cursor: pointer;
}

.item .img {
  height: 180px;
  vertical-align: middle;
  width: inherit;
}

.item .img img {
  max-height: 180px;
}

.item .description {
  position: relative;
  background-color: white;
}

.item .description .name {
  padding-top: 5px;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
}

.item .description .unit {
  margin-top: 5px;
  font-size: 10px;
  font-weight: bold;
  float: right;
  color: black;
}

.item .description .oldPrice {
  padding-top: 5px;
  font-size: 12px;
  color: #959595;
}

.item .description .price {
  padding-top: 5px;
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.item .description .remark {
  padding-top: 5px;
  font-size: 10px;
  color: red;
}

.item .hiddenFunction {
  text-align: center;
  color: black;
  display: none;
  padding: 6px;
  background-color: #0071bc;
}

.item .hiddenFunction .btn-default:hover {
  background-color: #f4ce11;
}

.item .flagSmall {
  position: absolute;
  font-size: 14px;
  z-index: 1;
}

.item .flagSmall .productGroupBannerHeaderRibbin {
  height: 34.4px;
  width: 10px;
  float: left;
  border: 1px solid;
  margin-left: -15px;
}

@media screen and (max-width: 1280px) {
  .item .flagSmall .productGroupBannerHeaderRibbin {
    display: none;
  }
}

.item .flagSmall .productGroupBannerHeader {
  float: left;
  text-align: center;
  padding-left: 0px;
}

.item .flagSmall .bottomleft {
  width: 0;
  height: 0;
  border-top: 10px solid;
  border-right: 20px solid transparent;
  float: left;
  margin-top: -2px;
}

.item .flagSmall .topleft {
  width: 0;
  height: 0;
  border-top: 10px solid;
  border-left: 20px solid transparent;
  float: right;
  margin-top: -2px;
}

.item:hover {
  border: 1px solid #0071bc;
}

.item:hover img {
  opacity: 1;
}

.item:hover .hiddenFunction {
  z-index: 2;
  width: 100%;
  display: block;
  position: absolute;
  border: 1px solid #0071bc;
}

.prductCategoryBox {
  background-color: white;
  height: 233.5px;
  font-size: 18px;
  color: #3e5668;
  border: 1px solid #e1e1e1;
  margin-bottom: 20px;
}

.prductCategoryBox:hover {
  background-color: #f4ce11;
  transition: 1s ease;
}

.ecommerceNavbar {
  color: black;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ecommerceNavbar .text, .ecommerceNavbar header a, header .ecommerceNavbar a, .ecommerceNavbar a, .ecommerceNavbar a:hover,
.ecommerceNavbar a:focus {
  color: black;
  text-decoration: none;
}

.pagination {
  display: inline-block;
  padding-left: 0px;
  margin: 0;
}

.pagination a, .pagination header a:hover, header .pagination a:hover,
.pagination header a:focus, header .pagination a:focus {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  margin-right: 2px;
  margin-left: 2px;
  cursor: pointer;
}

.pagination a.active {
  border-bottom: 2px solid #f4ce11;
  background-color: #f4ce11;
  color: black;
}

.pagination a:not(.active) {
  border-bottom: 2px solid #0071bc;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

h1 {
  color: black;
  float: left;
  border-bottom: 2px solid transparent;
  transition: all 0.5 linear;
  position: relative;
  margin-top: 24px;
}

h1:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  top: 100%;
  transition: all 200ms linear;
  background-color: #0066ff;
  margin-top: 5px;
}

hr {
  margin-top: 5px;
  border-top: 1px solid #e4e4e4;
}

.catMenuLeft {
  font-size: 14px;
}

.catMenuLeft a, .catMenuLeft header a:hover, header .catMenuLeft a:hover,
.catMenuLeft header a:focus, header .catMenuLeft a:focus {
  padding-left: 10px;
  padding-right: 10px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.catMenuLeft a:hover {
  color: #0071bc;
}

.catMenuLeft .active {
  color: #0071bc;
}

.btn-brand {
  border: 1px solid #0071bc;
  background: white;
}

.btn-brand:hover, .btn-brand .active {
  background: #0071bc;
  color: white;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.checkbox-uncheck-custom {
  color: #fff;
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 1px solid #666;
}

.checkbox-check-custom {
  color: #f4ce11;
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 1px solid #0071bc;
}

.filter-header {
  color: #fff;
  background: #0071bc;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 11px;
  padding-top: 8px;
}

.filter-header .text-mod {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.filter-header .btn-mod {
  display: inline-block;
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.viewProduct {
  color: #727272;
}

.viewProduct .wishlist {
  color: black;
  cursor: pointer;
  font-size: 18px;
}

.viewProduct .imgBox {
  height: 440px;
  display: table-cell;
  vertical-align: middle;
}

.viewProduct .thump {
  border: 1px solid #dadada;
  width: 62px;
  height: 62px;
  margin-bottom: 10px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.viewProduct .thump:hover {
  border: 1px solid #f4ce11;
}

.viewProduct .arrowRecomendedItem {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.viewProduct .arrowRecomendedDisable {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  color: #d6d6d6;
}

.viewProduct .arrowRecomendedItem:hover {
  color: #0071bc;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.textColorBlue {
  color: #0071bc;
}

.textColorYellow {
  color: #f4ce11;
}

a:hover .textColorYellow {
  text-decoration: none;
}

.bgColorYellow {
  background-color: #f4ce11;
}

.bgColorBlue {
  background-color: #0071bc;
}

h3 {
  font-size: 26px;
  color: black;
}

h2 {
  font-size: 30px;
  color: black;
}

.form-control {
  border-radius: 0px;
}

@media screen and (max-width: 1280px) {
  .xs-center {
    text-align: center;
  }
}

#mobileMenu {
  background: white;
  color: black;
  overflow: scroll;
  max-height: 90vh;
}

#sliderContent {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .modal-mobile-max {
    width: 95%;
  }
}

.main {
  min-height: calc(100vh - 320px);
}

@media screen and (min-width: 1023px) {
  .main {
    margin-top: 105px;
  }
}

@media screen and (max-width: 320px) {
  .fontSize {
    font-size: 12px;
  }
}

.angular-google-map-container {
  height: 400px;
  width: 100%;
}

.job-border {
  border: #f4ce11 2px solid;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (min-width: 1280px) {
  .menu-fotter {
    text-align: right;
  }
}

@media screen and (max-width: 1280px) {
  .menu-fotter {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 1280px) {
  .copyright-fotter {
    text-align: center;
  }
}

.not-sell-box {
  color: white;
  border: 1px solid black;
  background-color: #0071bc;
  padding: 10px;
}

.not-sell-box a, .not-sell-box header a:hover, header .not-sell-box a:hover,
.not-sell-box header a:focus, header .not-sell-box a:focus {
  color: white;
  text-decoration: underline;
}

.po-ex-detail .pending-invoice {
  color: #444;
  padding: 10px;
  text-align: center;
  background-color: #e8cf08;
  font-size: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 10px;
}

.po-ex-detail .pending-so {
  color: white;
  padding: 10px;
  text-align: center;
  background-color: #dc3545 !important;
  font-size: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 10px;
}

.po-ex-detail .po-ex-logo {
  float: right;
  max-width: 150px;
  padding-top: 10px;
}

@media screen and (max-width: 1280px) {
  .po-ex-detail .po-ex-logo {
    max-width: 85px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .h1,
  h1 {
    font-size: 30px;
  }
}

.text-status-1 {
  color: #ffc107 !important;
}

.btn-line {
  background: #00b900;
  color: white;
  margin: 5px;
}

.btn-line:hover {
  color: white;
}

.btn-line:active {
  color: white;
}

.btn-fb {
  background: #3b5998;
  color: white;
  margin: 5px;
}

.btn-fb:hover {
  color: white;
}

.btn-fb:active {
  color: white;
}

.card {
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 4px;
  border-radius: 0 0 2px 2px;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-success {
  color: #28a745 !important;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.product-group-top {
  color: black;
}

.home-category-item {
  border: 1px solid #f5f5f5;
  padding-top: 0px;
}

.brand-list-item {
  border: 1px solid #f5f5f5;
  min-height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.brand-list-item.active {
  background-color: #dcf7ff;
}

.line-icon {
  width: 60px;
  position: fixed;
  bottom: 40px;
  right: 20px;
}

.home-pg-header {
  color: #0071bc !important;
  padding: 10px;
  background-color: #0071bc;
  border: 1px solid #f7f7f7;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
}

.letter {
  color: black;
  flex: 1;
  font-size: 20px;
  cursor: pointer;
}

.letter.disable {
  cursor: not-allowed;
  color: #d6d6d6;
  flex: 1;
  font-size: 20px;
}

.break {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

.col-xs-110,
.col-sm-110,
.col-md-110,
.col-lg-110 {
  position: relative;
  min-height: 1px;
  padding-right: 0px;
  padding-left: 0px;
}

.col-xs-110 {
  width: 10%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-110 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-110 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-110 {
    width: 10%;
    float: left;
  }
}

.col-md-18 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 992px) {
  .col-md-18 {
    width: 12.5%;
    float: left;
  }
}

.poiter {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.brand-select-letter {
  display: flex;
  z-index: 1;
  position: sticky;
  background: whitesmoke;
}

@media (min-width: 768px) {
  .brand-select-letter {
    top: 98px;
  }
}

@media (max-width: 768px) {
  .brand-select-letter {
    top: 54px;
  }
}

.text-center {
  text-align: center;
}

.social-contact {
  margin-top: 10px;
  font-size: 24px !important;
}

.social-contact a, .social-contact header a:hover, header .social-contact a:hover,
.social-contact header a:focus, header .social-contact a:focus {
  margin-left: 6px;
  margin-right: 6px;
  color: #fffcdc;
}

.social-contact a:hover {
  cursor: pointer;
}

.social-contact small {
  display: block;
  font-size: 18px;
}

.text-on {
  color: #444;
  background-color: whitesmoke;
  position: absolute;
  left: 225px;
  z-index: 1;
  padding-left: 20px;
  padding-right: 20px;
  min-height: -webkit-fill-available;
  min-width: 250px;
  border-left: 2px solid #e6e6e6;
}

.sub-cat-menu {
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.cat-active {
  background: #eed80b;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-10 {
  margin-top: 10px;
}

.poiter {
  cursor: pointer;
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
}

#Certificate-banners {
  margin-top: 10px;
  margin-bottom: 10px;
}

#Certificate-banners a {
  padding-left: 0px !important;
}

#Certificate-banners a img {
  background-color: white;
  width: 90px;
}
